var headroom, myElement;

Array.prototype.append = function(el) {
  if (this.push != null) {
    this.push(el);
  }
  return this;
};

myElement = document.querySelector("header");

headroom = new Headroom(myElement, {
  offset: 200
});

headroom.init();

document.addEventListener('DOMContentLoaded', function() {});
