Array.prototype.append = (el) ->
  this.push el if this.push?
  this

myElement = document.querySelector("header")
headroom  = new Headroom(myElement,
  offset: 200
)
headroom.init()

document.addEventListener 'DOMContentLoaded', () ->
  return